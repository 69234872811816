<template lang="pug">
    include ./../media/pug/mixins
    +b.login
        template(v-if="!isLoading")
            +e.login-container
                +e.IMG.logo(src="./../media/icons/neb-logo.svg")
            +e.item Статистика по документам БЕН РАН
            +e.item
                +e.INPUT.input(
                    id="login"
                    placeholder="Логин"
                    v-model="userLogin"
                    v-on:keyup.enter="isHasInfo ? login() : undefined"
                )
            +e.item
                +e.INPUT.input(
                    type="password"
                    id="password"
                    placeholder="Пароль"
                    v-model="password"
                    v-on:keyup.enter="isHasInfo ? login() : undefined"
                )
                +e.item._error(v-if="errorAuth") Неверный логин или пароль
            +e.item
                +e.button(
                    v-bind:class="{'login__button_active': isHasInfo}"
                    v-on:click="isHasInfo ? login() : undefined"
                ) Войти
</template>

<script>

	import axios from "axios";
	import {mapGetters} from "vuex";

	export default {
		name: 'login',
        data() {
			return {
				userLogin: '',
                password: '',
				errorAuth: false
            }
        },
        computed: {
			isHasInfo() {
				return this.password !== '' && this.userLogin !=='';
            },
            ...mapGetters([
		        'isLoading'
	        ])
        },
        methods: {
			async login() {
				try {
					const response = await axios.post(`${location.origin}/api/v1/operator/login`, {
							login: this.userLogin,
							password: this.password
						})
					if (response.status === 200) location.reload();
				} catch(error) {
					if (error.response) {
						if (error.response.status === 401) {
							this.errorAuth = true
						} else {
							console.error(error)
                        }
                    }
				}
            }
        }
	}
</script>

<style lang="sass" src="./../media/sass/components/login.sass"></style>
