<template lang="pug">
    include ./../media/pug/mixins
    +b.statistic
        +e.general-title {{ statisticTitle }}
        +e.download(
            v-if="tabName === 'details' && update && getStatistic.items.length"
            v-on:click="download()"
            ) Скачать CSV
        +e.TABLE.table
            +e.THEAD.head
                +e.TH.title(
                    v-for="title in tableHeaders[tabName]"
                    v-bind:class="{'statistic__title_important': title === 'Платные книговыдачи'}"
                    v-bind:key="title"
                    ) {{ title }}
            +e.TBODY(v-if="update")
                +e.TR.row(
                    v-if="tabName !== 'lending'"
                    v-for="item, index in statistic"
                    v-bind:key="'item' + index"
                    )
                    +e.TD.cell(
                        v-for="cell, index in item"
                        v-bind:key="'cell' + cell + index"
                        v-bind:class="{'statistic__title_important': index === 6 && tabName === 'general'}"
                        ) {{ cell }}
                +e.TR.row(v-if="tabName === 'lending'")
                    +e.TD.cell(
                        v-for="cell, index in statistic"
                        v-bind:key="'cell' + index"
                        v-bind:class="{'statistic__cell_important': index === 3}"
                    ) {{ cell }}
        +b.pagination(v-if="update && statistic !== [] && getStatistic.meta && getStatistic.meta.pageCount > 1")
            +e.IMG.item-image(
                src="./../media/icons/arrow.svg"
                v-on:click="currentPage !== 1 ? changePage(currentPage - 1) : undefined"
                v-bind:class="{'pagination__item-image_disabled': currentPage === 1}"
                )
            +e.item._first-dots(v-if="currentPage >  4") ...
            +e.item(
                v-for="page in getStatistic.meta.pageCount"
                v-if="isVisible(page)"
                v-bind:class="{'pagination__item_active': page === currentPage, 'pagination__item_one': page === 1, 'pagination__item_count': page === getStatistic.meta.pageCount}"
                v-on:click="changePage(page)"
            ) {{ page }}
            +e.item._last-dots(v-if="getStatistic.meta.pageCount - currentPage >  3") ...
            +e.IMG.item-image._last(
                src="./../media/icons/arrow.svg"
                v-bind:class="{'pagination__item-image_disabled': currentPage === getStatistic.meta.pageCount}"
                v-on:click="currentPage !== getStatistic.meta.pageCount ? changePage(currentPage + 1) : undefined"
            )


</template>

<script>

	import {mapGetters} from "vuex";
	import axios from 'axios';
	import encodeToWin from './../helpers/encodeToWin'

	export default {
		name: 'statistic',
        props: {
			tabName: {
				type: String,
                default: 'details'
            },
            dates: {
				type: Object,
                default: () => {}
            },
            update: {
				type: Boolean,
                default: false
            }
        },
		data() {
			return {
				perPage: 20,
				currentPage: 1,
				statistic: [],
				tableHeaders: {
					details: [
						'Идентификатор документа',
                        'Название документа',
                        'Автор документа',
                        'Наименование издательства',
                        'Идентификатор пользователя из ЕСИА',
                        'Идентификатор пользователя РГБ',
                        'Детальная информация о браузере',
                        'Номер страницы',
                        'Вид книговыдачи',
                        'Идентификатор сессии',
                        'Дата просмотра страницы',
                    ],
					general: [
						'Идентификатор документа',
                        'Название документа',
                        'Автор документа',
						'Наименование издательства',
                        'Ознакомительные просмотры страниц',
                        'Просмотренные страницы из книговыдачи',
                        'Платные книговыдачи',
						'Повторные книговыдачи',
						'Сессии ознакомительного просмотра',
                        'Уникальные пользователи'
                    ],
					lending: [
						'Востребованные уникальные документы',
                        'Ознакомительные просмотры страниц',
                        'Просмотренные страницы из книговыдачи',
                        'Платные книговыдачи',
						'Повторные книговыдачи',
                        'Сессии ознакомительного просмотра',
                        'Уникальные пользователи'
                    ]
                }
            }
		},
		computed: {
			...mapGetters([
                'getStatistic',
                'isLoading',
                'isUpdateStats'
			]),
			statisticTitle() {
				let curTitle;
				switch (this.tabName) {
					case 'details':
						curTitle = 'Получение детальной статистики о просмотрах страниц документов';
						break;
					case 'general':
						curTitle = 'Получение общей статистики о книговыдачах, сгруппированной по документам';
						break;
					case 'lending':
						curTitle = 'Получение общей статистики о книговыдачах для всего издательства\n';
						break;
                }
                return curTitle;
            }
        },
        watch: {
	        getStatistic() {
	        	this.createStatisticArray();
            },
	        isUpdateStats() {
	        	if (this.update) {
			        this.$store.dispatch('fetchStatistic', {
				        type: this.tabName,
				        dates: this.dates,
				        page: this.currentPage,
				        perPage: this.perPage
			        });
                }
            },
            tabName() {
	        	this.statistic = [];
	        	this.currentPage = 1;
	        	if (this.update) {
			        this.$store.dispatch('fetchStatistic', {
				        type: this.tabName,
				        dates: this.dates,
				        page: this.currentPage,
				        perPage: this.perPage
			        });
                }
            }
        },
		methods: {
			download() {
				axios.get(`${location.origin}/api/v1/statistic/view-log`, {
					params: {
						fromDate: this.dates.start,
						toDate: this.dates.end,
						page: this.currentPage,
						perPage: this.perPage,
                        asCsv: 1
					}
				}).then(response => {
					const hiddenElement = document.createElement('a');
					// encodeToWin переводит из UTF8 в windows-1251
					hiddenElement.href = URL.createObjectURL(new Blob([encodeToWin(response.data)]));
					hiddenElement.setAttribute('download', 'upload.csv');
					// для FF нужно добавить в body
					document.body.appendChild(hiddenElement);
					hiddenElement.click();
					document.body.removeChild(hiddenElement);
                });
            },
			isVisible(index) {
				const count = this.getStatistic.meta.pageCount;
				return (Math.abs(index - this.currentPage) <= 2) || index === 1 || index === count
            },
			changePage(page) {
				this.currentPage = page;
				this.$store.dispatch('fetchStatistic', {
					type: this.tabName,
					dates: this.dates,
					page,
					perPage: this.perPage
				});
            },
			checkTypeBook(type) {
				let text;
				switch(type) {
					case 'first':
						text = 'Оплаченная книговыдача';
                        break;
                    case 'recurring':
                        text = 'Повторная книговыдача';
                        break;
                    case 'preview':
                    	text = 'Ознакомительный фрагмент';
                    	break;
                    default:
                    	text = 'Отсутсвтует';
                        break;
                }
                return text;
            },
			createStatisticArray() {
				this.statistic = [];
				if (this.tabName === 'details') {
					this.getStatistic.items.forEach((item) => {
						this.statistic.push([
							item.document.id,
                            item.document.title,
                            item.document.author,
                            item.publisherName,
                            item.user.esiaId,
                            item.user.id,
                            item.user.browserInfo,
                            item.pageNumber,
                            this.checkTypeBook(item.loanType),
                            item.readingSessionId,
                            item.viewedAt
                        ])
					});
					return;
                }
				if (this.tabName === 'general') {
					this.getStatistic.items.forEach((item) => {
						this.statistic.push([
							item.document.id,
							item.document.title,
							item.document.author,
							item.publisherName,
							item.previewPageCount,
							item.loanPageCount,
							item.uniqueFirstLoanCount,
							item.uniqueRecurringLoanCount,
							item.uniquePreviewCount,
							item.userCount
						])
					});
					return;
                }
                this.statistic = [
	                this.getStatistic.uniqueDocumentCount,
	                this.getStatistic.previewPageCount,
	                this.getStatistic.loanPageCount,
	                this.getStatistic.uniqueFirstLoanCount,
	                this.getStatistic.uniqueRecurringLoanCount,
	                this.getStatistic.uniquePreviewCount,
	                this.getStatistic.userCount
                ];
            }
        }
	}
</script>

<style lang="sass" src="./../media/sass/components/statistic.sass"></style>
