<template lang="pug">
    include ./../media/pug/mixins
    +b.tabs
        template
            +e.tabs-menu-content
                +e.tabs-block
                    +e.item(
                        v-on:click="activateTab('details')"
                        v-bind:class="{'tabs__item_active': currentTab === 'details'}"
                        ) Детальная
                    +e.item(
                        v-on:click="activateTab('general')"
                        v-bind:class="{'tabs__item_active': currentTab === 'general'}"
                        ) Общая
                    +e.item(
                        v-on:click="activateTab('lending')"
                        v-bind:class="{'tabs__item_active': currentTab === 'lending'}"
                        ) Книговыдачи
                +e.date-block
                    +e.INPUT.date(
                        type="date"
                        v-model="startDate"
                        v-on:keyup.enter="isDateEntered ? apply() : undefined"
                        )
                    +e.IMG.line(src="./../media/icons/line.svg")
                    +e.INPUT.date(
                        type="date"
                        v-model="endDate"
                        v-on:keyup.enter="isDateEntered ? apply() : undefined"
                        )
                +e.apply-block
                    +e.apply-btn(
                        v-on:click="isDateEntered ? apply() : undefined"
                        v-bind:class="{'tabs__apply-btn_active': isDateEntered}"
                        ) Применить
        template
            +e.loader.loader(v-if="isLoading")
                +e.IMG(src="./../media/icons/loader.svg" width="40")
            keep-alive
                Statistic(
                    v-if="!isLoading"
                    v-bind:tabName="currentTab"
                    v-bind:dates="propsDates"
                    v-bind:update="isShowStatistic"
                    )
</template>

<script>
    import Statistic from './statistic.vue'
    import {mapGetters} from "vuex";

	export default {
		name: 'tabs',
        components: {
			Statistic
        },
        data() {
			return {
				currentTab: 'details',
				startDate: '',
				endDate: '',
                propsDates: {},
                isShowStatistic: false
            }
        },
        computed: {
	        isDateEntered() {
	        	return this.startDate !== '' && this.endDate !== '' && this.validateDate();
            },
	        ...mapGetters([
		        'isLoading'
	        ])
        },
        methods: {
			activateTab(item) {
				this.currentTab = item;
				if (!this.isDateEntered) this.isShowStatistic = false;
            },
            validateDate() {
	            const first = new Date(this.startDate)
	            const last = new Date(this.endDate)
	            return last >= first
            },
            apply() {
				this.isShowStatistic = true;
                this.propsDates = {
                	start: this.startDate,
                    end: this.endDate
                }
                this.$store.commit('UPDATE_STATISTIC')
            }
        }
	}
</script>

<style lang="sass" src="./../media/sass/components/tabs.sass"></style>
