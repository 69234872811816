<template lang="pug">
  include ./media/pug/mixins
  +b.app
    template(v-if="!getIsLogin")
      Login
    template(v-else)
      Main
</template>

<script>
import Login from './components/login.vue'
import Main from './components/main.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Login,
    Main
  },
  computed: {
        ...mapGetters([
            'getIsLogin'
        ])
  },
    created() {
  	    this.$store.dispatch('fetchOrganization');
    }
}
</script>

<style lang="sass" src="./media/sass/app.sass"></style>
