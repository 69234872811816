import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		isLogin: false,
		statistic: {},
		isLoading: false,
		organiazation: {},
		isUpdateStats: false
	},
	mutations: {
		SET_LOGIN(state, payload) {
			state.isLogin = payload;
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload;
		},
		SET_STATISTIC(state, payload) {
			state.statistic = payload;
		},
		SET_ORGANIZATION(state, payload) {
			state.organiazation = payload;
		},
		UPDATE_STATISTIC(state) {
			state.isUpdateStats = !state.isUpdateStats
		}
	},
	actions: {
		async fetchOrganization({commit}) {
			try {
				commit('SET_IS_LOADING', true)
				const {data} = await axios.get(`${location.origin}/api/v1/operator/info`)
				if (data) {
					commit('SET_ORGANIZATION', data);
					commit('SET_LOGIN', true)
				}
				commit('SET_IS_LOADING', false)
			} catch(error) {
				if (error.response && error.response.status === 401) {
					// Пользователь не авторизован, загружаем компонент логина
					commit('SET_IS_LOADING', false)
				}
				console.error(error)
			}
		},
		async fetchStatistic({commit}, statisticInfo) {
			const {page, perPage, dates} = statisticInfo;
			let requestApi;
			switch (statisticInfo.type) {
				case 'details':
					requestApi = '/api/v1/statistic/view-log';
					break;
				case 'general':
					requestApi = '/api/v1/statistic/total/document';
					break;
				case 'lending':
					requestApi = '/api/v1/statistic/total/contract';
					break;
			}
			try{
				commit('SET_IS_LOADING', true)
				const {data} = await axios.get(`${location.origin}${requestApi}`, {
					params: {
						fromDate: dates.start,
						toDate: dates.end,
						page,
						perPage
					}
				})
				commit('SET_STATISTIC', data)
				commit('SET_IS_LOADING', false)
			} catch(error) {
				console.error(error)
			}
		}
	},
	getters: {
		getIsLogin: state => state.isLogin,
		isLoading: state => state.isLoading,
		getStatistic: state => state.statistic,
		getOrganization: state => state.organiazation,
		isUpdateStats: state => state.isUpdateStats
	},
	strict: process.env.NODE_ENV !== 'production'
});

export default store;
