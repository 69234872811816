<template lang="pug">
    include ./../media/pug/mixins
    +b.main
        +e.HEADER.header
            +e.title {{ getOrganization.contractTitle }}
            +e.user
                +e.IMG(
                src="./../media/icons/user.svg"
                v-on:click="toggleMenu()"
                    )
                +e.user-menu(v-if="isUserMenuOpen")
                    +e.menu-item {{ getOrganization.email }}
                    +e.menu-item(v-on:click="openModal('Добавить пользователя')") Добавить пользователя
                    +e.menu-item(v-on:click="openModal('Изменить пароль')") Изменить пароль
                    +e.menu-item(v-on:click="logOut()") Выход
        +e.loader.loader(v-if="isLoading && !getOrganization.contractTitle")
            +e.IMG(src="./../media/icons/loader.svg" width="40")
        +e.content(v-else)
            keep-alive
                tabs
        +b.modal(
                v-if="isOpenModal"
                v-on:mousedown.self="closeModal()"
            )
            +e.content
                +e.header
                    +e.title {{ title }}
                    +e.close-btn(
                            v-on:click="closeModal()"
                        )
                        +e.IMG(
                            src="./../media/icons/close.svg"
                        )
                +e.form(v-if="!isValidResponse")
                    +e.INPUT.input(
                        v-if="title === 'Добавить пользователя'"
                        v-bind:class="{'modal__input_with_error': !isValidMail}"
                        v-model="mail"
                        placeholder="Email"
                        v-on:blur="validateMail()"
                    )
                    +e.INPUT.input(
                        v-if="title === 'Изменить пароль'"
                        v-bind:class="{'modal__input_with_error': mailErrorMessage || isShortPassword}"
                        type="password"
                        v-model="oldPassword"
                        placeholder="Текущий пароль"
                    )
                    +e.error(v-if="mailErrorMessage") {{ mailErrorMessage }}
                    +e.error(v-if="oldPasswordErrorMessage") {{ oldPasswordErrorMessage }}
                    +e.new-password
                        +e.INPUT.input(
                            v-bind:class="{'modal__input_with_error': isShortPassword && newPassword.length < 6}"
                            type="password"
                            v-model="newPassword"
                            v-bind:placeholder="title === 'Добавить пользователя' ? 'Пароль' : 'Новый пароль'"
                        )
                        +e.error(v-if="isShortPassword && newPassword.length < 6") Слишком короткий пароль
                        +e.INPUT.input(
                            type="password"
                            v-model="confirmNewPassword"
                            v-bind:class="{'modal__input_with_error': isShortPassword && confirmNewPassword.length < 6}"
                            v-bind:placeholder="title === 'Добавить пользователя' ? 'Подтверждение пароля' : 'Повтор нового пароля'"
                            v-on:keyup.enter="isValidForm ? submit() : undefined"
                        )
                        +e.error(v-if="isShortPassword && confirmNewPassword.length < 6") Слишком короткий пароль
                    +e.submit-btn(
                        v-bind:class="{'modal__submit-btn_active': isValidForm}"
                        v-on:click="isValidForm ? submit() : undefined"
                    ) {{ title === 'Добавить пользователя' ? 'Добавить' : 'Изменить' }}
                +e.response(v-else) {{ requestText }}

</template>

<script>
    import tabs from './tabs.vue'
    import {mapGetters} from "vuex";
    import axios from "axios";
    import validator from 'validator';

	export default {
		name: 'mainComponent',
        data() {
			return {
				companyInfo: '',
				isUserMenuOpen: false,
				isOpenModal: false,
                title: '',
				newPassword: '',
				confirmNewPassword: '',
                mail: '',
				oldPassword: '',
				isValidMail: true,
                apiResponse: {},
				mailErrorMessage: '',
				isValidResponse: false,
                isShortPassword: false,
				passwordErrorMessage: '',
                oldPasswordErrorMessage: ''
            }
        },
        components: {
			tabs
        },
        computed: {
            ...mapGetters([
                'getOrganization',
                'isLoading'
            ]),
            isValidForm() {
            	const isTrueNewPassword = this.newPassword !== '' && this.newPassword === this.confirmNewPassword;
            	if(this.title === 'Добавить пользователя') {
            		return this.mail !== '' && isTrueNewPassword && this.isValidMail
                }
            	return this.oldPassword && isTrueNewPassword;
            }
        },
        methods: {
			async submit() {
				if (this.title === 'Добавить пользователя') {
					if (this.newPassword.length < 6) {
						this.isShortPassword = true;
						this.passwordErrorMessage = 'Слишком короткий пароль';
						return;
					}
					try {
						this.apiResponse = await axios.post(`${location.origin}/api/v1/operator`, {
							email: this.mail,
                            password: this.newPassword
                        })
                        if(this.apiResponse.data) {
	                        this.isValidResponse = true;
	                        this.requestText = `Пользователь с email ${this.mail} успешно создан`;
	                        setTimeout(() => {
	                        	this.closeModal();
                            }, 3000)
                        }
                    } catch(error) {
						this.isValidMail = false;
						this.mailErrorMessage = 'Данный email уже существует';
                    }
                }
				if (this.title === 'Изменить пароль') {
					if (this.oldPassword.length < 6 || this.newPassword.length < 6) {
						this.isShortPassword = true;
						this.passwordErrorMessage = this.newPassword.length < 6 ? 'Слишком короткий пароль' : '';
						if (this.oldPassword.length < 6 ) {
							this.oldPasswordErrorMessage = 'Слишком короткий пароль';
                        }
						return;
                    }
					try {
						this.apiResponse = await axios.patch(`${location.origin}/api/v1/operator/password`, {
							currentPassword: this.oldPassword,
							newPassword: this.newPassword
						})
						if(this.apiResponse.status === 200) {
							this.isValidResponse = true;
							this.requestText = 'Парол успешно изменен';
							setTimeout(() => {
								this.closeModal();
							}, 3000)
						}
					} catch(error) {
                        if (error.request && error.request.status === 400) {
	                        this.oldPasswordErrorMessage = 'Пароль не совпадает';
                        } else {
	                        this.mailErrorMessage = 'Непредвиденная ошибка';
                        }
					}
                }
            },
	        validateMail() {
		        this.isValidMail = validator.isEmail(this.mail);
                this.mailErrorMessage =  this.isValidMail ? '' : 'Неверный формат ввода email';
            },
			closeModal() {
				this.isOpenModal = false;
				if (this.isValidResponse) {
					this.isValidResponse = false;
					this.apiResponse = {};
					this.mail = '';
					this.oldPassword = '';
					this.newPassword = '';
					this.confirmNewPassword = '';
					this.isShortPassword = false;
					this.oldPasswordErrorMessage = '';
					this.mailErrorMessage = '';
					this.passwordErrorMessage = '';
                }
            },
            openModal(title) {
	            this.isOpenModal = true;
	            if (title !== this.title) {
	            	this.newPassword = '';
	            	this.confirmNewPassword = '';
		            this.isShortPassword = false;
		            this.oldPasswordErrorMessage = '';
		            this.passwordErrorMessage = '';
		            this.mailErrorMessage = '';
                }
	            this.title = title;
	            this.isUserMenuOpen = false;
            },
			toggleMenu() {
				this.isUserMenuOpen = !this.isUserMenuOpen;
            },
            async logOut() {
	            try {
		            const response = await axios.post(`${location.origin}/api/v1/operator/logout`)
		            if (response.status === 200) location.reload();
	            } catch(error) {
		            console.error(error)
	            }
            }
        }
	}
</script>

<style lang="sass" src="./../media/sass/components/main.sass"></style>
